.chicklet {
  background-color: #e9f0ff;
  color: var(--color-primary-v3);
  font-size: 13px;
  padding: 1px 8px 3px 8px;
  border-radius: 2px;
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 10px;
  border: 0;
  position: relative;
}
.chicklet:hover {
  background-color: var(--color-primary-v3);
  color: var(--color-white-v3);
}

.upgrade_popop {
  display: none;
  position: absolute;
  font-size: 10px;
  font-weight: 400;
  background: var(--color-primary-v3);
  padding: 12px 15px;
  border-radius: 4px;
  top: 130%;
  left: 0;
  transform: translate(-40%, 0%);
  text-align: center;
  width: 200px;
  z-index: 1;
}
.upgrade_popop:after {
  width: 0px;
  height: 0px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--color-primary-v3);
  position: absolute;
  content: '';
  top: -4px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.chicklet:hover > .upgrade_popop {
  display: inherit;
}

.upgrade_btn {
  font-size: 10px;
  font-weight: 600;
  color: var(--color-secondary-v7);
  background: var(--color-white-v1);
  border-radius: 4px;
  width: 100%;
  outline: 0;
  border: 0;
  margin: 10px 0 0 0;
  height: 30px;
}
.upgrade_btn:hover {
  background: var(--color-white-v3);
}
