.upgrade_modal {
  padding: 40px 55px;
}
.modal_title {
  font-size: 36px;
  font-weight: 700;
  color: var(--color-primary-v1);
  margin: 0;
}
.modal_title span {
  color: var(--color-primary-v3);
}
.modal_subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 20px 0 25px 0;
  white-space: normal;
  color: var(--color-secondary-v4);
}

.field_dv {
  padding: 0 0 26px 0;
  position: relative;
}

.inpt_txt {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  outline: 0;
  padding: 10px 10px 10px 0;
  color: var(--color-primary-v1);
  font-weight: 600;
  font-size: 14px;
}
.inpt_txt::placeholder,
.txtarea::placeholder {
  color: #969696;
  font-weight: 400;
}

.txtarea_dv {
  padding: 0 0 10px 0;
  position: relative;
  border-bottom: 1px solid #eaeaea;
}

.txtarea {
  width: 100%;
  height: 80px;
  border: 0;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
}
.txtarea:focus {
  /* background: #f9f9f9; */
  outline: 0;
}
.word_remaining {
  position: absolute;
  right: 0px;
  font-size: 12px;
  color: #d3d3d3;
  font-size: 12px;
  font-weight: 600;
  bottom: 2px;
}
.error {
  color: #ff3739;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top: 45px;
  left: 0;
}

.error_txtarea {
  color: #ff3739;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  bottom: -20px;
  left: 0;
}

/* -----------------Modal CSS Start ---------------------- */

.close_modal {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 30px;
  z-index: 1;
  color: var(--color-black-v1);
}
.close_modal:hover {
  opacity: 0.7;
}

.btn_black {
  background: var(--color-primary-v1) !important;
  color: var(--color-white-v3) !important;
  font-size: 14px;
  font-weight: 600;
  height: 42px;
  width: 100%;
  margin: 30px 0 0 0;
}

.btn_black:hover {
  background: #13213b !important;
  color: var(--color-white-v3) !important;
}

.experts_container {
  background: #fff8e2;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 0px 0 0;
  padding: 10px;
}

.experts_container a {
  font-weight: 700;
  text-decoration: underline !important;
  cursor: pointer;
}
