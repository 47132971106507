.input_icon {
  position: relative;
}

.input_icon i {
  position: absolute;
  color: var(--color-secondary-v8);
  top: 7px;
  left: 10px;
  letter-spacing: 8px;
}

.search_input {
  border: 1px solid var(--color-secondary-v6);
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  text-indent: 25px;
  outline: none;
}

.search_input_warning {
  border: 1px solid red;
}

.search_sugg_block {
  position: relative;
}

.search_popup {
  position: absolute;
  top: 47px;
  left: -32px;
  width: 100%;
  border-radius: 5px;
  background: var(--color-white-v3);
  z-index: 2;
  box-shadow: 5px 5px 12px rgb(25 59 104 / 14%);
  overflow: hidden;
}

.popup_list {
  padding: 20px 30px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* .popup_list:last-child {
  border: 0;
} */

.popup_list ul {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.popup_list ul li {
  display: inline-block;
  vertical-align: middle;
}

.popup_list .inf_pic {
  position: relative;
}

.popup_list .inf_pic img {
  border-radius: 50%;
}
.img_bg {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.inf_pic_cont {
  border-radius: 50%;
  overflow: hidden;
}
.popup_list ul li .inf_pic .inf_soc {
  border-radius: 50%;
  font-size: 8px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: -10px;
  top: 12px;
  border: 2px solid var(--color-white-v3);
  line-height: 17px;
  text-align: center;
  color: var(--color-white-v3);
}

.inf_soc.insta {
  background: linear-gradient(
    180deg,
    #6d48cb 0%,
    #ad3ba5 21.88%,
    #cc39a6 35.94%,
    #fa634b 53.12%,
    #ff7d45 76.04%,
    #ffd153 100%
  );
}
.inf_soc.ytube {
  background: #cd201f;
  font-size: 10px !important;
  line-height: 19px !important;
}

.popup_list ul li:nth-child(2) {
  width: 200px;
}

.popup_list p {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-primary-v1);
  line-height: 20px;
  padding: 0;
  margin: 0;
}

.popup_list p i.verify {
  color: #42a5f5;
  font-style: normal;
  padding: 0 5px 0 5px;
}

.popup_list p span {
  display: inline-block;
  color: var(--color-primary-v1);
  opacity: 50%;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 20px 0 0px;
}

.popup_list p.info {
  color: var(--color-primary-v1);
  opacity: 50%;
  font-weight: 400;
  border-left: 1px solid #9f9f9f;
  margin: 0 0 0 20px;
  padding: 0 0 0 20px;
}

.popup_list .right_arrow {
  position: absolute;
  right: 15px;
  top: 35px;
}

.popup_list:hover {
  background: #fafafa;
  cursor: pointer;
}

p.moresrh {
  padding: 22px 22px 22px 46px;
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

p.moresrh a {
  text-decoration: none;
  text-align: left;
  line-height: unset;
  color: #858992 !important;
}
p.moresrh a span {
  color: var(--color-primary-v3) !important;
}

p.moresrh a:hover {
  text-decoration: underline;
}

p.moresrh img {
  float: left;
  margin: 0 25px 0 0;
  width: 24px;
}

@media screen and (max-width: 640px) {
  .search_input {
    border: 0;
    padding: 9px;
    background: var(--color-white-v2);
    text-indent: 30px;
  }
  .input_icon {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 360px) {
  .popup_list {
    padding: 20px 15px;
  }
  .popup_list p.info {
    border-left: 0;
    margin: 0 0 0 45px;
  }
  p.moresrh {
    padding: 22px 22px 22px 45px;
  }
}
