.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_container {
  display: block;
  z-index: 10000;
}

.modal_dialog {
  max-width: 87%;
}

.main_container {
  display: flex;
}

.right_part {
  margin: 30px 40px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.progress_tracker_container {
  display: flex;
  margin-bottom: 17px;
}

.progress_tracker {
  height: 10px;
  width: 266px;
  background-color: #f6f8fa;
  border-radius: 100px;
  margin: 2px 10px 0 0;
}

.progress_completed {
  display: block;
  height: 10px;
  background-color: #3da634;
  border-radius: 100px;
}

.progress_value {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  color: #7a7a7a;
}

.welcome_title {
  margin: 170px 0 20px 0;
  font-size: 44px;
  font-weight: 700;
  line-height: 53px;
  color: #212121;
}

.welcome_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  color: #4d4d4d;
}

.btns {
  display: flex;
  gap: 10px;
  bottom: 0px;
  right: 0px;
  position: absolute;
}

.next_btn {
  width: fit-content;
  background-color: #0b1425;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 14.5px 75px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.next_btn_disabled {
  width: fit-content;
  background-color: grey;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 14.5px 75px;
  border-radius: 6px;
  cursor: not-allowed;
  text-align: center;
}

.get_started_btn {
  width: fit-content;
  background-color: #0b1425;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 14.5px 55px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  text-align: center;
}

.get_started_btn_disabled {
  width: fit-content;
  background-color: grey;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 14.5px 55px;
  border-radius: 6px;
  cursor: not-allowed;
  border: none;
  text-align: center;
}

.back_btn {
  width: fit-content;
  background-color: #f3f3f3;
  color: #0b1425;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 14.5px 75px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.title {
  margin: 30px 0 30px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #212121;
}

.option {
  display: flex;
  margin-bottom: 17px;
}

.collective_option {
  width: 100%;
}

.option_name {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #0b1425;
  top: 2px;
  position: relative;
  margin-left: 15px;
}

.other_textarea {
  width: 58%;
  height: 42px;
  background-color: #f9f9f9;
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  margin-left: 15px;
  margin-top: 12px;
}
.dropdn {
  background: var(--color-white-v3) !important;
  color: var(--color-black-v1) !important;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #dbdbdb !important;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 35px;
  width: 220px;
  position: relative;
  padding: 0px 20px 0px 12px;
  margin: 16px 0 0 16px;
}

.dropdn::after {
  position: absolute;
  contain: '\F282' !important;
  right: 10px;
  top: 15px;
  font-size: 18px;
}

.drop_mnu {
  border-radius: 5px !important;
  font-weight: 600;
  font-size: 12px !important;
  padding: 2px 0 !important;
  box-shadow: 5px 5px 12px rgb(0 0 0 / 15%) !important;
  width: 220px;
}

.drop_item {
  border-radius: 0px !important;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-primary-v1) !important;
  padding: 1px 16px !important;
  line-height: 37px;
}

.drop_item:hover {
  color: var(--color-primary-v1) !important;
  background: var(--color-white-v2) !important;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .modal_overlay {
    display: none;
  }

  .modal_container {
    display: none;
  }

  .modal_mobile_container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 2px;
    background-color: white;
    z-index: 10000;
    overflow: auto;
  }

  .main_container {
    display: flex;
    min-height: 520px;
  }

  .right_part {
    margin: 40px 32px 30px 20px;
  }

  .welcome_text_container {
    min-height: 352px;
  }

  .text_container {
    min-height: 305px;
    padding-bottom: 30px;
  }

  .welcome_title {
    margin: 0px 0 20px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #212121;
  }

  .welcome_desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #4d4d4d;
  }

  .title {
    margin: 0px 0 30px 0;
  }

  .progress_tracker_container {
    margin-bottom: 30px;
  }

  .progress_tracker {
    width: 92%;
  }

  .other_textarea {
    width: 95%;
  }

  .btns {
    padding: 15px;
    position: fixed;
    justify-content: flex-end;
    margin-top: 50px;
    background: white;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .modal_overlay {
    display: none;
  }

  .modal_container {
    display: none;
  }

  .modal_mobile_container {
    position: fixed;
    top: 51px;
    left: 0;
    bottom: 0;
    right: 2px;
    background-color: white;
    z-index: 10000;
    overflow: auto;
  }

  .main_container {
    display: flex;
    min-height: 520px;
  }

  .right_part {
    margin: 40px 32px 30px 20px;
    overflow: unset;
  }

  .welcome_text_container {
    min-height: 352px;
  }

  .text_container {
    height: 54vh;
    overflow: auto;
    padding-bottom: 25px;
  }

  .welcome_title {
    margin: 0px 0 20px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #212121;
  }

  .welcome_desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #4d4d4d;
  }

  .title {
    margin: 0px 0 30px 0;
  }

  .progress_tracker_container {
    margin-bottom: 30px;
  }

  .progress_tracker {
    width: 92%;
  }

  .other_textarea {
    width: 95%;
  }

  .btns {
    position: fixed;
    justify-content: flex-end;
    padding: 15px;
    width: 100%;
    background: white;
  }
  .back_btn {
    width: 49%;
    padding: 14.5px 0px;
  }
  .next_btn {
    width: 49%;
    padding: 14.5px 0px;
  }
  .next_btn_disabled {
    width: 49%;
    padding: 14.5px 0px;
  }
  .get_started_btn {
    width: 49%;
    padding: 14.5px 0px;
  }
  .get_started_btn_disabled {
    width: 49%;
    padding: 14.5px 0px;
  }
}

@media screen and (min-width: 1024px) {
  .modal_mobile_container {
    display: none;
  }
}
