.only_mobile {
  display: none;
}
.only_desktop {
  display: block;
}
.right {
  right: 15px !important;
  left: unset !important;
}
.row {
  display: flex;
  flex-direction: row;
}
.btn_no_border {
  color: var(--color-primary-v3);
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  height: 42px;
  margin: 10px 0 0 15px;
  cursor: pointer;
}

.btn_black {
  background: var(--color-primary-v1) !important;
  color: var(--color-white-v3) !important;
  font-size: 14px;
  font-weight: 600;
  height: 42px;
  padding: 0px 25px;
  margin: 2px 0 0 24px;
}

.nav_header li {
  padding: 0 7px;
}

.nav_header li a {
  line-height: 30px;
}
.header_icon {
  font-size: 18px;
  color: var(--color-secondary-v7);
  opacity: 0.7;
  padding: 2px 0 0 0;
  display: inline-block;
}

.nav_header li a i:hover {
  opacity: 1;
}
.nav_header li a img.thumb {
  border-radius: 50%;
}

.dropdown_toggle::after {
  content: none !important;
}
.img_bg {
  width: auto;
  height: auto;
  overflow: hidden;
}
.inf_pic_cont {
  border-radius: 50%;
  overflow: hidden;
}
.dropdown_text {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-secondary-v1);
}

.dropdown_text:hover,
.dropdown_text:active {
  background: var(--color-white-v1);
}

.bg_header {
  background-color: var(--color-primary-v4) !important;
  height: 63px;
  padding: 0 !important;
}
.sugg_block {
  width: 500px;
}
.input_icon {
  position: relative;
  padding: 2px 32px 2px 0;
  margin-top: 4px;
  float: right;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.input_icon i {
  position: absolute;
  color: var(--color-primary-v1);
  opacity: 0.5;
  top: 9px;
  left: 14px;
  letter-spacing: 8px;
}

.search_input {
  border: 1px solid #d7d7d7;
  padding: 7px 7px 7px 40px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  width: 292px;
  height: 38px;
  outline: none;
}

.search_input::placeholder {
  color: var(--color-primary-v1);
  opacity: 0.25;
}
.search_input:focus {
  background-color: rgba(255, 255, 255, 0.7);
}

/* **********************Sidebar CSS Start************************************ */

.sidebar {
  position: fixed;
  left: 0;
  width: 70px;
  height: 100vh;
  padding: 10px 0 10px 0px;
  background-color: var(--color-primary-v1);
  z-index: 9999;
  transition: all 0.1s ease;
}

.logo_div {
  position: relative;
}
.logo {
  text-align: center;
  padding: 25px 0;
  height: 90px;
}
.logo .logo_icon {
  width: 30px;
  height: 30px;
}
.logo .full_logo {
  width: 100px;
  margin: -8px 0 0 51px;
}

.sidebar .full_logo {
  display: none;
}
.sidebar:hover .full_logo,
.sidebar.close .full_logo {
  display: block;
}
.sidebar:hover .logo_icon,
.sidebar.close .logo_icon {
  display: none;
}
.sidebar:hover {
  width: 200px;
}

.close {
  width: 200px;
}
.close:hover {
  max-width: 200px;
}

.slide_icon {
  border-radius: 50%;
  border: 2px solid var(--color-primary-v4);
  background: var(--color-primary-v1);
  height: 26px;
  width: 26px;
  color: var(--color-white-v3);
  text-align: center;
  line-height: 20px;
  display: none;
}

.sidebar:hover .slide_icon {
  display: block;
}

.sidebar.close .slide_icon {
  display: block;
}

.slide_icon i {
  font-size: 12px;
}

.close .arrow .slide_left {
  display: block;
  line-height: 23px;
}
.close .arrow .pin {
  display: none;
}

.arrow {
  position: absolute;
  top: 10px;
  right: -14px;
}
.arrow .pin,
.arrow .slide_left {
  cursor: pointer;
}
.arrow .slide_left {
  display: none;
}

.category_name {
  font-size: 10px;
  font-weight: 600;
  color: #dde8f8;
  padding-left: 15px;
}

.menu_bar ul {
  padding: 0;
}
.nav_link {
  padding: 7px 10px;
}
.nav_link a {
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  padding: 8px 10px;
  color: var(--color-primary-v4) !important;
  text-decoration: none;
  white-space: nowrap;
}
.nav_link a img {
  width: 24px;
  margin: 0 0 0 4px;
}
.nav_link .title_nav {
  padding: 0 0 0 10px;
  line-height: 24px;
  margin-right: -5px;
}
.nav_link a:hover,
.nav_link a.active {
  background: rgba(255, 255, 255, 0.2);
  color: var(--color-white-v3) !important;
}
.top_margin {
  margin-top: 0px;
}
.top_margin a:hover {
  margin-top: 24px;
}
.sidebar:hover .top_margin {
  margin-top: 24px;
}
.nav_link .title_nav {
  display: none;
}

.sidebar:hover .nav_link .title_nav {
  display: block;
}
.close .nav_link .title_nav {
  display: block;
}
.category_name {
  display: none;
}
.close .category_name {
  display: block;
}
.sidebar:hover .category_name {
  display: block;
}

.category_divider {
  width: 38px;
  height: 3px;
  background: #dde8f8;
  opacity: 0.2;
  border-radius: 32px;
  margin-left: 16px;
}

.close .category_divider {
  display: none;
}
.sidebar:hover .category_divider {
  display: none;
}

.saas_container {
  position: relative;
  left: 70px;
  width: calc(100% - 70px);
  background-color: var(--color-primary-v5);
}

.whitelist_saas_container {
  position: relative;
  width: calc(100%);
  background-color: var(--color-primary-v5);
}

.close ~ .saas_container {
  left: 200px;
  width: calc(100% - 200px);
}

.container-wide {
  padding-top: 50px;
  padding-left: 100px;
}

@media screen and (max-width: 640px) {
  .only_mobile {
    display: block;
  }
  .only_desktop {
    display: none;
  }
  .bg_header {
    background-color: var(--color-primary-v1) !important;
    height: 52px;
  }

  .saas_container {
    left: 0px;
    width: calc(100% - 0px);
  }
  .nav_header li a.bar {
    color: var(--color-white-v3) !important;
    font-size: 30px;
    line-height: 50px;
  }
  .menu_bar ul {
    padding: 20px 0 0 0;
  }

  /* ************************mobile sidebar start*********************************** */

  .sidebar {
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: var(--color-primary-v4);
    top: 50px;
    z-index: 9999;
    border-radius: 0 0 16px 16px;
    padding: 0;
    box-shadow: 0px 48px 19px rgba(62, 124, 249, 0.01),
      0px 27px 16px rgba(62, 124, 249, 0.02),
      0px 12px 12px rgba(62, 124, 249, 0.03),
      0px 3px 7px rgba(62, 124, 249, 0.04), 0px 0px 0px rgba(62, 124, 249, 0.04);
  }

  .sidebar:hover {
    width: 100%;
  }

  .close {
    width: 100%;
  }
  .close:hover {
    max-width: 100%;
  }

  .nav_link a {
    color: var(--color-primary-v1) !important;
    font-weight: 500;
  }
  .nav_link a:hover {
    background: transparent;
    color: var(--color-primary-v3) !important;
  }
  .nav_link a.active {
    background: transparent;
    color: var(--color-primary-v3) !important;
    font-weight: 700;
  }
  .nav_link .title_nav {
    display: block;
  }
  .logo_div {
    top: 0px;
    position: fixed;
    width: 100%;
  }
  .logo {
    text-align: left;
    padding: 0;
    height: 50px;
    position: fixed;
    top: 0px;
  }

  .logo .logo_icon {
    margin: 10px 0 0 10px;
  }
  .logo .full_logo {
    width: 80px;
    height: 29px;
    margin: 10px 0 0 10px;
  }
  /* =========================== */
  .sidebar .full_logo,
  .sidebar.close .logo_icon {
    display: none !important;
  }
  .sidebar.close .full_logo,
  .sidebar .logo_icon {
    display: block !important;
  }

  .nav_header li:last-child {
    margin: -3px 40px 0 0;
  }
  span.bar {
    top: 0;
    right: 10px;
    color: var(--color-white-v3) !important;
    font-size: 30px;
    line-height: 50px;
    position: fixed;
    cursor: pointer;
  }
  .mob_menu {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    margin: 0 23px;
    padding: 20px 0 0 0 !important;
    list-style: none;
  }
  .mob_menu li {
    padding: 5px 0;
  }
  .mob_menu li a {
    padding: 8px 0;
  }
  .mob_menu li a img {
    margin: 0;
  }
  .mnu_info {
    text-align: center;
    color: var(--color-secondary-v1);
    opacity: 0.5;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 25px 25px 40px 25px;
  }
  .saas_container {
    position: relative;
    left: 0px;
    width: calc(100% - 0px);
  }
  .close ~ .saas_container {
    left: 0px;
    width: calc(100% - 0px);
  }

  .sidebar.close {
    height: auto !important;
    overflow: visible;
  }

  /* .sidebar.close .full_logo{display:block}
 */

  .nav_link a img {
    filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg)
      brightness(85%) contrast(97%);
  }
  .sidebar.close .slide_icon {
    display: none;
  }
  .sidebar:hover .slide_icon {
    display: none;
  }
  /* ************************mobile sidebar end*********************************** */
}

@media screen and (max-width: 360px) {
}
