:root {
  --color-primary-v1: #0b1425;
  --color-primary-v2: #606b80;
  --color-primary-v3: #6281bc;
  --color-primary-v4: #dde8f8;
  --color-primary-v5: #f6f8fa;

  --color-secondary-v1: #212121;
  --color-secondary-v2: #4d4d4d;
  --color-secondary-v3: #7a7a7a;
  --color-secondary-v4: #a6a6a6;
  --color-secondary-v5: #d3d3d3;
  --color-secondary-v6: #bcbcbc;
  --color-secondary-v7: #2c2c2c;
  --color-secondary-v8: #b3b3b3;
  --color-secondary-v9: #dedede;
  --color-secondary-v10: #8b8b8b;

  --color-white-v1: #f3f3f3;
  --color-white-v2: #f9f9f9;
  --color-white-v3: #ffffff;

  --color-black-v1: #000;

  --color-excellent: #985ef3;
  --color-Very-good: #3da634;
  --color-good: #f78859;
  --color-average: #ffc400;
  --color-too-low: #fc5656;
  --rs-picker-value: var(--color-primary-v1) !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--color-primary-v5) !important;
  scroll-behavior: smooth;
  padding-right: 0 !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

/* h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 42px;
}

h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
} */

.c-p1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
}

.c-p2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.c-p3 {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.c-p4 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.customSlider {
  /* max width of your slider */
  max-width: 300px;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 4px;
  /* thickness of the track */
  height: 4px;
  /* default color of your track */
  background: var(--color-secondary-v9);
  border-radius: 50px;
}

.popover-arrow {
  display: none !important;
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--color-primary-v3);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: white;
  /* shape of the thumb: circle */
  width: 12px;
  height: 12px;
  border: 3px solid var(--color-primary-v3);
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* nprogress Hide Spinner */
#nprogress .spinner {
  display: none;
}

.fade {
  cursor: default !important;
}

.toast_position {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
}

.lineGraphDate .rs-picker-toggle.rs-btn.rs-btn-default {
  height: 42px;
  border: 1px solid var(--color-secondary-v6) !important;
  border-radius: 4px !important;
  padding-top: 8px;
}

.lineGraphDate .rs-picker-toggle-clean.rs-btn-close {
  display: none;
}

.lineGraphDate .rs-picker-toggle-caret.rs-icon,
.lineGraphDate .rs-picker-toggle-clean.rs-btn-close {
  top: 10px !important;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
  left: unset !important;
  right: 6.5% !important;
}

.lineGraphDate
  .rs-picker-has-value.rs-picker-cleanable
  .rs-picker-toggle.rs-btn {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 28px 0px 16px;
}

.lineGraphDate2 .rs-picker-toggle.rs-btn.rs-btn-default {
  height: 42px;
  border: none !important;
  border-radius: 4px !important;
  padding-top: 8px;
  width: 300px;
}

.lineGraphDate2 .rs-picker-toggle-caret.rs-icon,
.lineGraphDate2 .rs-picker-toggle-clean.rs-btn-close {
  top: 11.5px !important;
}

.lineGraphDate2
  .rs-picker-has-value.rs-picker-cleanable
  .rs-picker-toggle.rs-btn {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 28px 0px 16px;
}

.lineGraphDate2 .rs-picker-toggle-textbox {
  background-color: #f9f9f9;
  opacity: 1;
}

.modal-open {
  overflow-y: auto !important;
}

.active > .page-link {
  background-color: #6281bc !important;
}

.tooltip-arrow {
  display: none !important;
}

input[type='radio'] {
  accent-color: #6281bc;
}

.toast-container {
  z-index: 10001 !important;
}
