/* ***************** Auth Left Section CSS Start ***************** */
.login_container {
  background: var(--color-primary-v4);
  height: 100vh;
  position: relative;
}

/* ----------Modal Css Start----------- */
.coupon_modal .vid_login_dv {
  padding: 0px 0px 0px 0px;
}
.coupon_modal .login_container {
  border-radius: 8px 0 0 8px;
}
.coupon_modal .vid_login_dv h2 {
  font-size: 28px;
  line-height: 34px;
}
.coupon_modal .vid_login_dv p {
  font-size: 12px;
}

.coupon_modal img.radius {
  border-radius: 0 0 0 8px;
}
.coupon_modal .login_container td {
  width: 50%;
}
.coupon_modal .login_container td:nth-child(3),
.coupon_modal .login_container td:nth-child(4),
.coupon_modal .login_container td:nth-child(5) {
  display: none;
}
.coupon_modal .login_container {
  height: 498px;
}
.coupon_modal .valign_cntr_left {
  height: 185px;
}
/* ----------Modal Css Ends----------- */
/* ----------Responsive Css start----------- */
@media only screen and (min-width: 1024px) and (max-width: 1083px) {
  .vid_login_dv {
    padding: 30px 30px 0px 30px;
  }
  .login_container td {
    width: 33%;
  }
  .login_container td:nth-child(4) {
    display: none;
  }
  .login_container td:nth-child(5) {
    display: none;
  }
  .valign_cntr_left {
    height: 37vh;
  }
  .vid_login_dv h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .coupon_modal .login_container td {
    width: 33%;
  }
  .coupon_modal .login_container td:nth-child(3) {
    display: revert;
  }
  .coupon_modal .login_container td:nth-child(4),
  .coupon_modal .login_container td:nth-child(5) {
    display: none;
  }
  .coupon_modal .valign_cntr_left {
    height: 245px;
  }
}

@media screen and (max-width: 768px) {
  .login_container {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .login_container {
    display: none;
  }
}
/* ----------Responsive Css start----------- */
/* ***************** Auth Left Section CSS Ends ***************** */
/* ***************** Auth right Section CSS Start ***************** */
.valign_cntr_form {
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100%;
}
.form_container {
  background: var(--color-white-v3);
  width: 100%;
  padding: 50px 110px;
}
.signup_block {
  overflow: auto;
  height: 100vh;
}

.signup_block::-webkit-scrollbar {
  width: 7px;
  border: 0;
}

.signup_block::-webkit-scrollbar-track {
  border-radius: 0px;
  background: transparent;
}

.signup_block::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: transparent;
}

.signup_block:hover::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c0c0c0;
}

.form_container h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-primary-v1);
  padding: 0 0 12px 0;
  margin: 0;
}
.form_container h2 span {
  color: var(--color-primary-v3);
}
.form_container p.sub_hding {
  color: #969696;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 10px 0;
}
.user_detail {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: var(--color-secondary-v1);
  padding: 20px 0 0 0;
}
.user_detail img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  float: left;
  margin: -7px 10px 0 0;
}
.user_detail span {
  color: var(--color-secondary-v4);
  font-weight: 500;
}
.login_box {
  padding: 30px 0 0 0;
}
.field_dv {
  padding: 0 0 26px 0;
  position: relative;
}
.error {
  color: #ff3739;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top: 45px;
  left: 0;
}
.error.termchk {
  position: unset;
  display: block;
  padding: 5px 0 0 0;
}

.inpt_txt {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  outline: 0;
  padding: 10px 10px 10px 0;
  color: var(--color-primary-v1);
  font-weight: 600;
  font-size: 14px;
}
.inpt_txt::placeholder {
  color: #969696;
  font-weight: 600;
}
.password_field {
  list-style: none;
  padding: 0;
  margin: 0;
}
.password_field li {
  display: inline-block;
  width: 14.6%;
  margin: 0 1%;
}
.password_field l:first-child {
  margin: 0 1% 0 0;
}
.inpt_code {
  width: 100%;
  height: 55px;
  margin: 0;
  border-radius: 5px;
  outline: 0;
  border: 1px dashed #bfbfbf;
  text-align: center;
  font-size: 20px;
}
.mr0 {
  margin: 0 !important;
}
.inpt_code[type='number']::-webkit-inner-spin-button,
.inpt_code[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.field_dv span.show_pswd {
  position: absolute;
  right: 0;
  top: 10px;
  color: #969696;
  opacity: 0.5;
}
.remamber {
  font-size: 14px;
  color: #9a9a9a;
  padding: 20px 0;
  font-weight: 600;
}
.chkbox {
  height: 15px;
  width: 15px;
  margin: 2px 10px 0 0;
  float: left;
}
.remamber a {
  color: var(--color-primary-v1);
  text-decoration: none;
}
.remamber a:hover {
  text-decoration: underline;
}
.remamber a.rm_pwd {
  float: right;
}

.term {
  font-size: 12px;
  color: var(--color-secondary-v4);
  padding: 0 0 20px 0;
  font-weight: 400;
}
.term a {
  color: var(--color-secondary-v1) !important;
  text-decoration: underline !important;
}

.term a:hover {
  text-decoration: none !important;
}

.dont_sign {
  font-size: 14px;
  color: #9a9a9a;
  padding: 15px 0 0 0;
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.dont_sign a {
  color: var(--color-primary-v1) !important;
  text-decoration: none;
}
.dont_sign a:hover {
  text-decoration: underline;
}
.dont_sign a.resend {
  color: var(--color-primary-v3) !important;
}

.search_btn {
  border-radius: 4px;
  height: 42px;
  font-size: 14px;
  font-weight: 600;
}

.search_btn svg {
  padding: 0 5px 0 0;
}

.search_btn.btn_black {
  background: var(--color-primary-v1);
  color: var(--color-white-v3);
}

.search_btn.btn_black:hover {
  background: #13274e;
  color: var(--color-white-v3);
}

.search_btn.btn_grey {
  background: #f4f4f4;
  color: var(--color-primary-v1);
}

.search_btn.btn_grey:hover {
  background: #f0f0f0;
  color: var(--color-primary-v1);
}
.google_icon {
  margin: 0 10px 0 0;
  vertical-align: text-top;
}
.inpt_txt[type='number']::-webkit-inner-spin-button,
.inpt_txt[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* -------------modal css start ----------------------- */
.modal_height {
  height: 500px;
}

.close_modal {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 20px;
  z-index: 1;
}
.close_modal:hover {
  opacity: 0.7;
}

.coupon_modal {
  padding: 0;
}
.coupon_modal .form_container {
  padding: 50px 40px;
}

.coupon_modal .valign_cntr_form {
  height: 100%;
}

.coupon_modal .signup_block {
  overflow: auto;
  height: 565px;
}

.left_title {
  font-size: 34px;
  font-weight: 700;
}
.left_desc {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  padding-top: 10px;
  padding-bottom: 36px;
}
.left_btn {
  width: 204px;
  height: 42px;
  padding-top: 8px;
  margin-left: 10%;
  background: #0b1425;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.right_section {
  padding: 107px 30px;
  width: 53%;
}
.right_section_title {
  font-size: 16px;
  font-weight: 700;
}
.right_section_desc {
  font-size: 12px;
  font-weight: 400;
  padding-left: 5%;
  padding-bottom: 44px;
}

/* -------------modal css end ----------------------- */
/* ------------Responsive CSS Start --------------- */

@media screen and (max-width: 1440px) {
  .signup_block {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .modal_height {
    height: unset;
  }

  .modal_height {
    min-height: unset;
  }
}

/* ------------Responsive CSS Ends --------------- */
/* ***************** Auth right Section CSS Ends ***************** */
